import {openDB} from "idb/build/index";

const DB_NAME = 'Wahoo-Logrrator';
const LOGS = 'Logs';

export class Database {


    static getDb() {
        return openDB(DB_NAME, 2, {
            upgrade(db) {
                db.createObjectStore(LOGS);
            },
        });
    }

    static async saveLogText(logId, text) {
        console.log("saveTextFile", logId, text.length / 1000, "kb")
        const db = await this.getDb();
        await db.put(LOGS, text, logId);
    }

    static async loadLogText(logId) {
        const db = await this.getDb();
        return await db.get(LOGS, logId);
    }

}