import {Autocomplete, Avatar, Divider, Fab, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, TextField} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import LogoutIcon from '@mui/icons-material/Logout';
import PeopleIcon from '@mui/icons-material/People';
import {useState} from "react";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

function UITitleBar({authUser, user, users, onAction}) {

    //console.log("UITitleBar user", user)
    // console.log("UITitleBar authUser",authUser)

    let name = (authUser.first ? authUser.first : "") + " " + (authUser.last ? authUser.last : "");
    if (name.trim() === "")
        name = "?";
    const [anchorEl, setAnchorEl] = useState(null);


    function closeMenu() {
        setAnchorEl(null);
    }

    function doMenuAction(action = "Logout") {
        closeMenu();
        return onAction(action);
    }


    return (

        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px', gap: '10px', paddingLeft: '10px'}}>

            <IconButton
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                }} size="large"
            >
                <Avatar alt={name} src={authUser.avatar.url}/>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={closeMenu}
            >
                <MenuItem onClick={() => {
                    doMenuAction("ShowUsersCfgModal")
                }}>
                    <ListItemIcon>
                        <PeopleIcon fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>Configure Users</ListItemText>
                </MenuItem>
                <Divider/>
                <MenuItem onClick={() => doMenuAction("Logout")}>
                    <ListItemIcon>
                        <LogoutIcon fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>Log Out</ListItemText>
                </MenuItem>


            </Menu>

            <Autocomplete
                disablePortal
                value={users?.length > 0 ? user : null}
                options={users}
                onChange={(event, user) => {
                    onAction("SetUser", user);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name + " (" + option.id + ")"}
                sx={{width: 300}}
                renderInput={(params) =>
                    <TextField {...params} label="User"/>
                }
            />

            {user && <>
                <Fab size="small">
                    <RefreshIcon onClick={() => {
                        onAction("RefreshUser")
                    }}/>

                </Fab>
                <Fab size="small">
                    <ManageAccountsIcon onClick={() => {
                        onAction("OpenUser")
                    }}/>
                </Fab>
            </>}

        </div>
    );
}

export default UITitleBar;