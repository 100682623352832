import {Box, CircularProgress, Modal} from "@mui/material";
import {UIModal} from "./UIModal";
import {styled} from "@mui/system";
import UIDivider from "./UIDivider";

function UIModalRefreshProgress({progressInfo}) {

    const labelStyle = {
        display: 'inline-block',
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    };

    return (
        <>
            <Modal
                open={progressInfo != null}
            >

                <Box sx={UIModal.style}>

                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', height: '50px', gap: '20px'}}>
                        <CircularProgress variant={progressInfo?.percent === 0 ? "indeterminate" : "determinate"} value={progressInfo?.percent}/>
                        <h2>Refreshing</h2>
                    </div>

                    <UIDivider/>
                    <label style={labelStyle}>{progressInfo?.text}</label>

                </Box>
            </Modal>
        </>
    );
}

export default UIModalRefreshProgress;