import {useEffect, useState} from "react";
import {Log} from "../data/Log";
import {Button, Tooltip} from "@mui/material";
import {Database} from "../data/Database";

function UILogSelector({user, logs, onShowLogInfo}) {

    const [dayInfos, setDayInfos] = useState([]);
    const [selectedDayInfo, setSelectedDayInfo] = useState(null);

    const [hourInfos, setHourInfos] = useState([]);
    const [selectedHourInfo, setSelectedHourInfo] = useState(null);

    const [fiveInfos, setFiveInfos] = useState([]);
    const [selectedFiveInfo, setSelectedFiveInfo] = useState(null);

    const [sourceInfos, setSourceInfos] = useState([]);
    const [selectedSourceInfo, setSelectedSourceInfo] = useState(null);

    /** DAYS (depends on all logs) */
    useEffect(() => {

        console.log("useEffect update dayInfos by [user, logs]")
        console.log("user", user)
        console.log(logs?.length, "logs")

        setSelectedDayInfo(null)
        setSelectedSourceInfo(null)
        setSelectedHourInfo(null)
        setSelectedFiveInfo(null)
        onShowLogInfo(null)

        let _dayInfos = [];
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        let dayInfosWithLogs = 0;
        for (let i = 14; i >= 0; i--) {

            let dayDate = new Date();
            dayDate.setDate(today.getDate() - i);

            let dayLogs = [];
            for (let j = 0; j < logs.length; j++) {
                let log = logs[j];

                let filenameFromUrl = Log.getFilenameFromUrl(log?.file?.url);
                if (filenameFromUrl?.includes("2024-06-21_14-05")) {
                    console.error("Checking", filenameFromUrl)
                }

                if (!Log.isUser(log, user)) continue
                if (!Log.isOnDay(log, dayDate)) continue;

                //console.log("Day", dayDate, "has", log)
                dayLogs.push(log);
            }

            if (dayLogs.length > 0) dayInfosWithLogs++;

            // https://htmlcolorcodes.com/colors/shades-of-green/
            _dayInfos.push({
                date: dayDate, dateStr: formatDayInfo(dayDate), logs: dayLogs,
            });
        }

        console.log("useEffect update dayInfosWithLogs", dayInfosWithLogs)
        setDayInfos(_dayInfos)

    }, [user, logs]);

    /** SOURCES (depends on selected day) */
    useEffect(() => {

        console.log("useEffect update sourceInfos by [dayInfos, selectedDayInfo]")
        if (!selectedDayInfo) {
            setSourceInfos([]);
            setSelectedSourceInfo(null);
            setHourInfos([])
            setSelectedHourInfo(null)
            setFiveInfos([])
            setSelectedFiveInfo(null)
            return
        }

        let logsToSearch = selectedDayInfo.logs;

        let _sourceInfos = [];

        for (let i = 0; i < logsToSearch.length; i++) {
            let log = logsToSearch[i];

            let sourceId = Log.getSourceId(log);

            let sourceInfo = _sourceInfos.find(_sourceInfo => _sourceInfo.sourceId === sourceId);
            if (!sourceInfo) {
                sourceInfo = {
                    sourceId: sourceId, logs: []
                }
                _sourceInfos.push(sourceInfo);
            }
            sourceInfo.logs.push(log);
        }

        console.log("useEffect update sourceInfos", _sourceInfos.length)
        setSourceInfos(_sourceInfos);

    }, [dayInfos, selectedDayInfo]);

    /** HOURS (depends on day >> source) */
    useEffect(() => {

        console.log("useEffect update hourInfos by [sourceInfos, selectedSourceInfo]")

        if (!selectedSourceInfo) {
            setHourInfos([])
            setSelectedHourInfo(null)
            setFiveInfos([])
            setSelectedFiveInfo(null)
            return
        }

        let logsToSearch = selectedSourceInfo.logs;

        let _hourInfos = [];
        for (let i = 0; i < 24; i++) {

            let hourDate = new Date(selectedDayInfo.date);
            hourDate.setHours(i, 0, 0, 0);

            let _hourLogs = [];

            for (let j = 0; j < logsToSearch.length; j++) {
                let log = logsToSearch[j];

                if (!Log.isUser(log, user)) continue
                if (!Log.isOnHour(log, hourDate)) continue;

                //console.log("hour", i, "has log")
                _hourLogs.push(log);
            }

            _hourInfos.push({
                date: hourDate, dateStr: formatHourInfo(hourDate), logs: _hourLogs
            })
        }

        console.log("useEffect update hourInfos", _hourInfos.length)
        setHourInfos(_hourInfos)

    }, [sourceInfos, selectedSourceInfo]);

    /** FIVE (depends on day >> source >> hour) */
    useEffect(() => {

        console.log("useEffect update fiveInfos by [hourInfos, selectedHourInfo]")
        console.log("selectedHourInfo", selectedHourInfo)

        if (!selectedHourInfo) {
            setFiveInfos([])
            setSelectedFiveInfo(null);
            return
        }

        let logsToSearch = selectedHourInfo.logs;

        let _fiveInfos = [];
        for (let i = 0; i < 12; i++) {

            let fiveDate = new Date(selectedHourInfo.date);
            fiveDate.setMinutes(i * 5, 0, 0);

            let _fiveLogs = [];

            for (let j = 0; j < logsToSearch.length; j++) {
                let log = logsToSearch[j];

                if (!Log.isUser(log, user)) continue
                let onFive = Log.isOnFive(log, fiveDate);
                if (!onFive) continue;

                //console.log("five", i, "has log")
                _fiveLogs.push(log);
            }

            _fiveInfos.push({
                date: fiveDate, dateStr: formatFiveInfo(fiveDate), logs: _fiveLogs
            })
        }

        console.log("useEffect update fiveInfos", _fiveInfos.length)
        setFiveInfos(_fiveInfos)

    }, [hourInfos, selectedHourInfo]);

    useEffect(() => {
        console.log("useEffect update onShowLogs by [selectedFiveInfo]")
        loadLogs();
    }, [selectedFiveInfo]);

    async function loadLogs() {

        let startDate = new Date(selectedFiveInfo?.date)
        let logs = selectedFiveInfo?.logs
        if (!startDate || !logs) {
            onShowLogInfo(null)
            return
        }

        logs = logs.sort((a, b) => {
            const dateA = new Date(a.startDate);
            const dateB = new Date(b.startDate);
            return dateA - dateB;
        });

        let logInfo = {
            lines: []
        }

        console.log("loadLogs", logs.length, "logs")

        for (let i = 0; i < logs.length; i++) {

            let log = logs[i];

            console.log("loadLogs load", log.id)

            let text = await Database.loadLogText(log.id,);

            let endDate = new Date(startDate);
            endDate.setMinutes(endDate.getMinutes() + 5);
            let _logInfo = Log.parse(log, text, startDate, endDate);
            console.log("loadLogs loaded", log.id, (text.length / 1000) + "kb", _logInfo.lines.length + "lines")

            if (!logInfo.startDate) logInfo.startDate = _logInfo.startDate;
            logInfo.endDate = _logInfo.endDate;

            logInfo.lines.push(..._logInfo.lines)
            console.log("loadLogs accum", logInfo.lines.length + "lines")
        }

        onShowLogInfo(logInfo)
    }

    function formatDayInfo(date) {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const day = date.getDate();
        const month = months[date.getMonth()];
        return `${day}${month}`;
    }

    function formatHourInfo(date) {
        let hours = date.getHours();
        return `${hours}`;
    }

    function formatFiveInfo(date) {

        let mins = date.getMinutes();
        return `${mins}`;
    }

    const tdStyle = {
        // border: '1px solid #ddd',
        textAlign: 'center', padding: '2px', fontSize: '12px', // Adjust font size as needed
        whiteSpace: 'nowrap',
    }
    const buttonStyle = {
        paddingTop: '2px', paddingBottom: '2px', paddingLeft: '5px', paddingRight: '5px', minWidth: 0
    }

    return <>

        {/*DAYS*/}
        {user && <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
            <table style={{
                width: '100%', borderCollapse: 'collapse',
            }}>
                <tbody>
                <tr>

                    {dayInfos.map((_dayInfo, idx) => <td key={"Day:" + idx} style={tdStyle}>

                        <Tooltip title={"Day has " + _dayInfo.logs.length + " logs"}>
                               <span>  <Button
                                   variant={_dayInfo === selectedDayInfo ? "contained" : "outlined"}
                                   value={_dayInfo}
                                   disabled={_dayInfo.logs.length === 0}
                                   sx={buttonStyle}
                                   onClick={() => {
                                       setSelectedDayInfo(_dayInfo)
                                       setSelectedSourceInfo(null)
                                   }}
                               >
                                {_dayInfo.dateStr}
                            </Button></span>
                        </Tooltip>


                    </td>)}

                </tr>
                </tbody>
            </table>


        </div>}

        {/*SOURCES*/}
        {selectedDayInfo && <>
            <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                <table style={{
                    width: '100%', borderCollapse: 'collapse',
                }}>
                    <tbody>
                    <tr>
                        {sourceInfos.map((_sourceInfo, idx) => <td key={"Source:" + idx} style={tdStyle}>
                            <Tooltip title={"Source has " + _sourceInfo.logs.length + " logs"}>
                               <span> <Button
                                   variant={_sourceInfo === selectedSourceInfo ? "contained" : "outlined"}
                                   value={_sourceInfo}
                                   sx={buttonStyle}
                                   onClick={() => {
                                       setSelectedSourceInfo(_sourceInfo)
                                       setSelectedHourInfo(null)
                                   }}>
                                    {_sourceInfo.sourceId}
                                </Button></span>
                            </Tooltip>
                        </td>)}

                    </tr>
                    </tbody>
                </table>

            </div>

        </>
        }

        {/*HOURS*/}
        {selectedSourceInfo && <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
            <table style={{
                width: '100%', borderCollapse: 'collapse',
            }}>
                <tbody>
                <tr>

                    {hourInfos.map((_hourInfo, idx) => <td key={"Hour:" + idx} style={tdStyle}>
                        <Tooltip title={"Hour has " + _hourInfo.logs.length + " logs"}>
                                <span><Button
                                    variant={_hourInfo === selectedHourInfo ? "contained" : "outlined"}
                                    value={_hourInfo}
                                    disabled={_hourInfo.logs.length === 0}
                                    sx={buttonStyle}
                                    onClick={() => {
                                        setSelectedHourInfo(_hourInfo)
                                        setSelectedFiveInfo(null)
                                    }}
                                >
                                    {_hourInfo.dateStr}
                                </Button></span>
                        </Tooltip>

                    </td>)}

                </tr>
                </tbody>
            </table>
        </div>}

        {/*FIVE MIN PERIODS*/}
        {selectedHourInfo && <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
            <table style={{
                width: '100%', borderCollapse: 'collapse',
            }}>
                <tbody>
                <tr>

                    {fiveInfos.map((_fiveInfo, idx) => <td key={"Five:" + idx} style={tdStyle}>
                        <Tooltip title={"This 5min has " + _fiveInfo.logs.length + " logs"}>
                                <span><Button
                                    variant={_fiveInfo === selectedFiveInfo ? "contained" : "outlined"}
                                    value={_fiveInfo}
                                    disabled={_fiveInfo.logs.length === 0}
                                    sx={buttonStyle}
                                    onClick={() => {
                                        setSelectedFiveInfo(_fiveInfo)
                                    }}
                                >
                                    {_fiveInfo.dateStr}
                                </Button></span>
                        </Tooltip>

                    </td>)}

                </tr>
                </tbody>
            </table>
        </div>}


    </>;
}

export default UILogSelector;