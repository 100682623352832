import '../App.css';
import {useEffect, useState} from "react";

import {Cloud} from "../data/Cloud";
import UITitleBar from "./UITitleBar";
import UIDivider from "./UIDivider";
import UILogSelector from "./UILogSelector";
import UILogFile from "./UILogFile";
import UIModalLogin from "./UIModalLogin";
import UIModalUsersCfg from "./UIModalUsersCfg";
import UIModalRefreshProgress from "./UIModalRefreshProgress";

const DEFAULT_USERS =
    [
        {name: "api-testing-login", isProduction: false, id: 391407},
        {name: "adbtestapp", isProduction: false, id: 289855},
        {name: "Tester McTesterson", isProduction: false, id: 347434},
        {name: "Ashley Baker", isProduction: false, id: 172287},
        {name: "wsm-test", isProduction: false, id: 369692},
    ]


function UIMain() {

    //
    // AuthUser
    //
    const [authUser, setAuthUser] = useState(() => {
        return JSON.parse(localStorage.getItem('authUser'));
    });
    useEffect(() => {
        localStorage.setItem('authUser', JSON.stringify(authUser));
        setUser(null)
    }, [authUser]);

    //
    // User
    //
    const [user, setUser] = useState(() => {
        return JSON.parse(localStorage.getItem('user'));
    });
    useEffect(() => {
        localStorage.setItem('user', JSON.stringify(user));
    }, [user]);

    //
    // Users
    //
    const [users, setUsers] = useState(() => {
        const _users = JSON.parse(localStorage.getItem('users'));
        return _users || DEFAULT_USERS;
    });
    useEffect(() => {
        localStorage.setItem('users', JSON.stringify(users));
    }, [users]);

    //
    // Logs
    //
    const [logs, setLogs] = useState(() => {
        const storedLogs = JSON.parse(localStorage.getItem('logs'));
        return storedLogs || [];
    });
    useEffect(() => {
        localStorage.setItem('logs', JSON.stringify(logs));
    }, [logs]);

    const [logInfo, setLogInfo] = useState(null);
    const [showUsersCfgModal, setShowUsersCfgModal] = useState(false);
    const [refreshProgressInfo, setRefreshProgressInfo] = useState(null);


    function onUserAction(action, selUser) {
        console.log("onUserAction", action)
        switch (action) {

        case "SetUser": {
            setUser(selUser);
            break
        }
        case "OpenUser": {
            if (!user) return
            window.open(Cloud.getCloudUserUrl(user), '_blank');
            break
        }
        case "RefreshUser": {
            if (!user) return
            let _logs = [...logs];
            Cloud.fetchLogFiles(user, authUser, setRefreshProgressInfo, (fetchedLogs) => {

                for (let i = 0; i < fetchedLogs.length; i++) {
                    let fetchedLog = fetchedLogs[i];

                    //let prevLog = _logs.find(_log => _log.id === fetchedLog.id);
                    _logs = _logs.filter(_log => _log.id !== fetchedLog.id);
                    _logs.push(fetchedLog);

                    //if (prevLog && prevLog.startDate) {
                    //    fetchedLog.startDate = prevLog.startDate;
                    //    fetchedLog.endDate = prevLog.endDate;
                    //}
                }

                setLogs(_logs);
            })
            break
        }
        case "Logout": {
            setAuthUser(null)
            break
        }
        case "ShowUsersCfgModal": {
            setShowUsersCfgModal(true)
            break
        }
        default:
            console.error("onUserAction invalid action", action)
            break
        }
    }

    function onShowLogInfo(logInfo) {
        console.log("onShowLogInfo", logInfo?.lines.length, "lines")
        setLogInfo(logInfo);

    }

    let filteredUsers = users.filter((_user) => authUser && _user.isProduction === authUser.isProduction);

    return (
        <>
            {authUser && <>
                <UIDivider/>
                <UITitleBar authUser={authUser} user={user} users={filteredUsers} onAction={onUserAction}/>

                <UIDivider/>
                <UILogSelector user={user} logs={logs} onShowLogInfo={onShowLogInfo}/>

                <UILogFile logInfo={logInfo}/>

                <UIModalUsersCfg open={showUsersCfgModal} users={users} authUser={authUser} setUsers={(users, close) => {
                    setUsers(users)
                    setShowUsersCfgModal(!close)
                }}/>

                <UIModalRefreshProgress progressInfo={refreshProgressInfo}/>

            </>}

            <UIModalLogin open={!authUser} onComplete={(authUser) => {
                setAuthUser(authUser)
            }}/>

        </>
    );
}

export default UIMain;
