import pako from "pako";
import JSZip from "jszip";
import { Log } from "./Log";
import { Database } from "./Database";

export class Cloud {
  static getCloudUserUrl(user) {
    if (user.isProduction) return "https://org.wahooligan.com/users/" + user.id;
    else return "https://org.staging.wahooligan.com/users/" + user.id;
  }

  static async fetch(method, isProd, path, token, reqJson, callback) {
    try {
      let baseUrl = isProd
        ? process.env.REACT_APP_PROD_CLOUD_URL
        : process.env.REACT_APP_STAGING_CLOUD_URL;
      let url = baseUrl + path;

      const response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          "WF-USER-TOKEN": token,
        },
        body: reqJson ? JSON.stringify(reqJson) : null,
      });
      if (!response.ok) {
        // Check if the request was successful
        console.error("fetchData FAILED", response);
        response.failReason = "HTTP Status: " + response.status;
        callback(response);
        return;
      }
      const rspJson = await response.json(); // Parse the JSON from the response
      console.log("fetchData OK", rspJson);
      callback(rspJson);
    } catch (error) {
      console.error("fetchData FAILED", error); // Handle errors
      callback({ failReason: error.message });
    }
  }

  static async fetchUsers(search, authUser, callback) {
    const req = {
      query: search,
    };

    console.log(">> fetch in fetchUsers");
    await Cloud.fetch(
      "POST",
      authUser.isProduction,
      "api/v1/user_searches",
      authUser.token,
      req,
      (rspJson) => {
        console.log("<< fetch in fetchUsers", rspJson);
        callback(rspJson);
      },
    );
  }

  static async fetchLogFile(url, callback) {
    console.log(">> fetch in fetchLogFile", url);
    const response = await fetch(url);
    console.log("<< fetch in fetchLogFile", response);
    if (!response.ok) {
      console.error("fetchLogFile fetch FAILED", response);
      callback("");
      return;
    }

    const arrayBuffer = await response.arrayBuffer();

    //console.log(">> unzip_JSZip in fetchLogFile");
    await this.unzip_JSZip(arrayBuffer, (text) => {
      if (text.length > 0) {
        //console.log("<< unzip_JSZip in fetchLogFile OK", text.length / 1000, "kb")
        callback(text);
        return;
      }

      //console.log(">> unzip_pako in fetchLogFile");
      this.unzip_pako(arrayBuffer, (text) => {
        if (text.length > 0) {
          //console.log("<< unzip_pako in fetchLogFile OK", text.length / 1000, "kb")
          callback(text);
          return;
        }

        console.error("unzip_JSZip and unzip_pako FAILED");
        callback("");
      });
    });
  }

  static async fetchLogFiles(user, authUser, onProgress, onComplete) {
    if (!user) {
      console.log("fetchLogFiles no user");
      return;
    }

    onProgress({ percent: 0, text: "" });

    console.log(">> fetchLogs in fetchLogFiles");
    await this.fetchLogs(user, authUser.token, async (logs) => {
      console.log("<< fetchLogs in fetchLogFiles", logs.length + "logs");
      if (!Array.isArray(logs)) {
        return;
      }

      // Copy logs

      for (let i = 0; i < logs.length; i++) {
        const log = logs[i];
        console.log("fetchLogFiles checking", log);

        const logId = log.id;
        const url = log?.file?.url;
        const name = Log.getFilenameFromUrl(url);

        if (name.includes("json.gz")) {
          console.log("fetchLogFiles skipping JSON", log);
          continue;
        }

        // Update progress (we show this on modal)
        const percent = (i * 100) / logs.length;
        onProgress({ percent: percent, text: name + "..." });

        // Load/fetch the log text
        let text = await Database.loadLogText(logId);
        if (text == null) {
          console.log(">> fetchLogFile in fetchLogFiles", logId);
          await Cloud.fetchLogFile(url, async (_text) => {
            text = _text;

            let textKb = text.length / 1000;
            console.log(
              "<< fetchLogFile in fetchLogFiles",
              logId,
              textKb,
              "kb",
            );

            console.log(">> saveTextFile in fetchLogFiles", textKb, "kb");
            await Database.saveLogText(logId, text);
            console.log("<< saveTextFile in fetchLogFiles", textKb, "kb");
          });
        } else {
          let textKb = text.length / 1000;
          console.log("fetchLogFiles already downloaded", logId, textKb, "kb");
        }

        // We do not trust the start_time/end_time from the JSON.
        // We set our own startDate/endDate from the log lines
        console.log(">> Log.parse in fetchLogFiles");
        let logInfo = Log.parse(log, text);
        console.log(
          "<< Log.parse in fetchLogFiles startDate=" + logInfo.startDate,
        );
        if (logInfo.startDate) {
          log.startDate = logInfo.startDate;
          log.endDate = logInfo.endDate;
        } else {
          console.error("fetchLogFiles parse FAILED (no startDate)", log);
          console.error(
            "fetchLogFiles parse FAILED (no startDate)",
            text?.substring(0, 100),
          );
          if (log.start_time) {
            log.startDate = new Date(log.start_time);
            log.endDate = logInfo.end_time ? new Date(log.end_time) : null;
          } else {
            console.error(
              "fetchLogFiles parse FAILED (no startDate and start_time)",
              log,
            );
          }
        }
      }

      console.log("fetchLogFiles complete", logs.length + "logs");
      onProgress(null);
      onComplete(logs);
    });
  }

  static async fetchLogs(user, token, callback) {
    // Create a new Date object for the current date and time
    const now = new Date();

    // Set the time to midnight
    now.setHours(0, 0, 0, 0);

    // Subtract 14 days
    const fourteenDaysAgo = new Date(now);
    fourteenDaysAgo.setDate(now.getDate() - 14);

    let path =
      "/api/v1/users/" +
      user.id +
      "/application_logs?updated_after=" +
      fourteenDaysAgo.toISOString();

    //   path = "/api/v1/users/" + user.id + "/application_logs" ;

    await Cloud.fetch("GET", user.isProduction, path, token, null, callback);
  }

  static async login(email, password, isProduction, callback) {
    let reqJson = {
      email: email,
      password: password,
    };

    await Cloud.fetch(
      "POST",
      isProduction,
      "/api/v1/sessions/",
      null,
      reqJson,
      (rspJson) => {
        if (rspJson && rspJson.id) {
          let wahooligan = rspJson?.roles?.includes("data_manager");
          if (!wahooligan) {
            callback({ failReason: "Insufficient role" });
            return;
          }
        }

        callback(rspJson);
      },
    );
  }

  static async unzip_JSZip(arrayBuffer, callback) {
    try {
      const zip = new JSZip();
      const zipContent = await zip.loadAsync(arrayBuffer);
      const extractedFiles = [];

      for (const [relativePath, zipEntry] of Object.entries(zipContent.files)) {
        if (!zipEntry.dir) {
          const fileContent = await zipEntry.async("string"); // or 'uint8array' for binary files

          callback(fileContent);
          return;
          //extractedFiles.push({relativePath, content: fileContent});
        }
      }
    } catch (error) {
      //console.log("unzip_JSZip JSZip FAILED");
    }
    callback("");
  }

  static async unzip_pako(arrayBuffer, callback) {
    const gzipBlob = new Blob([arrayBuffer]);
    //console.log("unzip_pako gzipBlob.size", gzipBlob.size)

    // Convert the blob to an ArrayBuffer
    const reader = new FileReader();
    reader.readAsArrayBuffer(gzipBlob);
    reader.onload = () => {
      try {
        const arrayBuffer = reader.result;
        // Decompress the ArrayBuffer using pako
        const text = pako.inflate(arrayBuffer, { to: "string" });
        // Update state with the decompressed text content
        callback(text);
      } catch (error) {
        console.log("unzip_pako pako.inflate FAILED");
        callback("");
      }
    };
  }
}
